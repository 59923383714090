<template>
  <div>
    <v-row>
      <v-col cols="10">
        <h3>Customer Invoice</h3>
      </v-col>
    </v-row>

    <v-row class="justify-start">
      <v-col cols="10">
        <v-card :loading="loading" outlined>
          <v-card-text>
            <div class="d-flex align-start">
              <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="https://live.mediapal.net/ads/logos/tradedesk/mediapal.png"
                transition="scale-transition"
                width="150"
              />
              <div class="ml-auto text-right">
                <h3>INVOICE</h3>
                <p>MediaPal Programatic</p>
                <v-spacer></v-spacer>
                <h4>MobiGlobe Limited</h4>
                <p>
                  Mandera RD, 304, Kileleshwa<br />P.O Box 5536-00200
                  <br />Nairobi Area <br />Kenya
                </p>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="d-flex align-start my-5">
              <div>
                <p class="mb-0">BILL TO</p>
                <h4>{{ invoice?.customer?.name }}</h4>
                <p>{{ invoice?.billing_address }}</p>
                <v-spacer></v-spacer>
                <p>{{ invoice?.customer?.s }}</p>
              </div>
              <div class="ml-auto text-right">
                <p>
                  Invoice Number: <strong>{{ invoice?.invoice_number }}</strong>
                </p>
                <p>
                  P.O./S.O. Number:
                  <strong>{{ invoice?.campaign?.purchase_order?.po_number }}</strong>
                </p>
                <p>
                  Invoice Date: <strong>{{ invoice?.invoice_date }}</strong>
                </p>
                <p>
                  Payment Due: <strong>{{ invoice?.due_date }}</strong>
                </p>
                <p>
                  Amount Due (KES): <strong>{{ __amountDue }}</strong>
                </p>
              </div>
            </div>
            <!-- <v-divider></v-divider> -->
            <v-simple-table class="mb-5">
              <template v-slot:default>
                <thead class="blue lighten-5">
                  <tr>
                    <th>Items</th>
                    <th class="text-right">Price</th>
                    <th class="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="py-4">
                      <h4>{{ invoice?.service }}</h4>
                      <p>{{ invoice?.campaign?.name }}</p>
                      <p>Campaign No.: {{ invoice?.campaign?.id }}</p>
                    </td>
                    <td class="text-right">${{ invoice?.amount }}</td>
                    <td class="text-right">${{ invoice?.amount }}</td>
                  </tr>
                  <tr>
                    <td class="py-5"></td>
                    <td class="text-right font-weight-bold">
                      VAT {{ invoice?.vat }}% (P05169683V):
                    </td>
                    <td class="text-right">${{ invoice?.total }}</td>
                  </tr>
                  <tr>
                    <td class="py-5"></td>
                    <td class="text-right font-weight-bold">Total:</td>
                    <td class="text-right">${{ invoice?.total }}</td>
                  </tr>
                  <tr>
                    <td class="py-5"></td>
                    <td class="text-right font-weight-bold">
                      Amount Due (KES):
                    </td>
                    <td class="text-right">${{ __amountDue }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider></v-divider>
            <div class="d-flex align-start my-5">
              <div>
                <h4 class="mb-3">NOTES / TERMS</h4>
                <v-spacer></v-spacer>
                <p>{{ invoice?.notes }}</p>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="d-flex align-center justify-center mt-5">
              <div>www.mobiglobe.africa</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.fetchInvoice()
    }
  },

  methods: {
    async fetchInvoice() {
      this.loading = true
      try {
        await this.$store.dispatch("billing/getInvoice", this.$route.params.id)
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
  },

  computed: {
    invoice() {
      return this.$store.state.billing.invoice
    },
    __amountDue() {
      return this.invoice?.total - this.invoice?.paid 
    },
  },
}
</script>

<style scoped>
.v-application p {
  margin-bottom: 4px;
}
</style>
